"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setOfficerStatus = exports.resetOfficersListState = exports.resetOfficersMetricsState = exports.getOfficersMetrics = exports.getOfficers = exports.updateOfficerStatus = exports.getOfficerStatus = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const officerConstants_1 = require("./officerConstants");
const constants_1 = require("../../constants/constants");
exports.getOfficerStatus = toolkit_1.createAsyncThunk('officer/get_status/', async (_, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        return await network_1.get(officerConstants_1.OFFICER_STATUS_GET_URL, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.updateOfficerStatus = toolkit_1.createAsyncThunk('officer/update_status/', async ({ status }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        return await network_1.post(officerConstants_1.OFFICER_STATUS_UPDATE_URL, auth.token, { status })
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.getOfficers = toolkit_1.createAsyncThunk('officers/all', async ({ page, first_name, last_name, officer_type }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(first_name && { first_name__icontains: first_name }),
            ...(last_name && { last_name__icontains: last_name }),
            ...(officer_type !== undefined && { officer_type: String(officer_type) }),
        });
        const urlPath = `${officerConstants_1.OFFICERS_LIST_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getOfficersMetrics = toolkit_1.createAsyncThunk('schedules/getOfficers', async ({ page, first_name, last_name }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(first_name && { first_name__icontains: first_name }),
            ...(last_name && { last_name__icontains: last_name }),
        });
        const urlPath = `${officerConstants_1.OFFICERS_METRICS_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
const initialState = {
    metrics: {
        data: [],
        loadingStatus: typesConstants_1.PENDING_STATUS,
        currentPage: null,
        nextPage: null,
        previousPage: null,
        totalPages: null,
        totalEntries: null,
        pageSize: 50,
    },
    officers: {
        data: [],
        loadingStatus: typesConstants_1.PENDING_STATUS,
        currentPage: null,
        nextPage: null,
        previousPage: null,
        totalPages: null,
        totalEntries: null,
        pageSize: 50,
    },
    status: constants_1.OFFICER_STATUS_PENDING,
    pause_time: 0,
};
const OfficersSlice = toolkit_1.createSlice({
    name: 'officers',
    initialState,
    reducers: {
        resetOfficersMetricsState(state) {
            return {
                ...state,
                metrics: initialState.metrics,
            };
        },
        resetOfficersListState(state) {
            return {
                ...state,
                officers: initialState.officers,
            };
        },
        setOfficerStatus(state, { payload }) {
            return {
                ...state,
                status: payload.status,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getOfficersMetrics.pending, (state) => ({
            ...state,
            metrics: {
                ...state.metrics,
                loadingStatus: typesConstants_1.PENDING_STATUS,
            },
        }))
            .addCase(exports.getOfficersMetrics.fulfilled, (state, { payload }) => ({
            ...state,
            metrics: {
                ...state.metrics,
                loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
                data: payload.results,
                currentPage: payload.current,
                nextPage: payload.next,
                previousPage: payload.previous,
                totalPages: payload.total_pages,
                totalEntries: payload.total_entries,
                pageSize: payload.page_size,
            },
        }))
            .addCase(exports.getOfficersMetrics.rejected, (state) => ({
            ...state,
            metrics: {
                ...state.metrics,
                loadingStatus: typesConstants_1.FAILED_STATUS,
            },
        }))
            .addCase(exports.getOfficers.pending, (state) => ({
            ...state,
            officers: {
                ...state.officers,
                loadingStatus: typesConstants_1.PENDING_STATUS,
            },
        }))
            .addCase(exports.getOfficers.fulfilled, (state, { payload }) => ({
            ...state,
            officers: {
                ...state.officers,
                loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
                data: payload.results,
                currentPage: payload.current,
                nextPage: payload.next,
                previousPage: payload.previous,
                totalPages: payload.total_pages,
                totalEntries: payload.total_entries,
                pageSize: payload.page_size,
            },
        }))
            .addCase(exports.getOfficers.rejected, (state) => ({
            ...state,
            officers: {
                ...state.officers,
                loadingStatus: typesConstants_1.FAILED_STATUS,
            },
        }))
            .addCase(exports.getOfficerStatus.fulfilled, (state, { payload }) => ({
            ...state,
            status: payload.status,
            pause_time: payload.pause_time,
        }))
            .addCase(exports.updateOfficerStatus.pending, (state) => ({
            ...state,
            status: constants_1.OFFICER_STATUS_PENDING,
        }))
            .addCase(exports.updateOfficerStatus.fulfilled, (state, { payload }) => ({
            ...state,
            status: payload.status,
            pause_time: payload.pause_time,
        }));
    },
});
_a = OfficersSlice.actions, exports.resetOfficersMetricsState = _a.resetOfficersMetricsState, exports.resetOfficersListState = _a.resetOfficersListState, exports.setOfficerStatus = _a.setOfficerStatus;
exports.default = OfficersSlice.reducer;
