"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedTicketComments = exports.setSelectedTicket = exports.resetSelectedTicket = exports.resetTicketState = exports.deleteComment = exports.updateComment = exports.createComment = exports.getComments = exports.updateTicket = exports.createTicket = exports.getTicket = exports.getTickets = void 0;
const react_native_1 = require("react-native");
const toolkit_1 = require("@reduxjs/toolkit");
const typesConstants_1 = require("../../constants/typesConstants");
const utils_1 = require("../../utils/utils");
const network_1 = require("../../utils/network");
const ticketConstants_1 = __importStar(require("./ticketConstants"));
const constants_1 = require("../../constants/constants");
const initialState = {
    tickets: [],
    loadingStatus: typesConstants_1.PENDING_STATUS,
    selectedTicket: null,
    selectedTicketComments: [],
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 10,
};
exports.getTickets = toolkit_1.createAsyncThunk('tickets', async ({ page, id, customer_id, priority, assigned_team, status, email, phone_number, phone_number_bvn, reporter_type, subject, product, origin, reporter_id, assigned_officer_id, ticket_date, source, type, }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(id && { id }),
            ...(customer_id && { customer_id }),
            ...((priority === 0 || priority) && { priority: String(priority) }),
            ...((assigned_team === 0 || assigned_team) && { assigned_team: String(assigned_team) }),
            ...((status === 0 || status) && { status: String(status) }),
            ...((source === 0 || source) && { source: String(source) }),
            ...((type === 0 || type) && { type: String(type) }),
            ...((reporter_type === 0 || reporter_type) && { reporter__officer_type: String(reporter_type) }),
            ...(ticket_date && { datetime_created__date: ticket_date }),
            ...(subject && { subject: String(subject) }),
            ...((product === 0 || product) && { product: String(product) }),
            ...(origin && { origin: String(origin) }),
            ...(reporter_id && { reporter__id: String(reporter_id) }),
            ...(assigned_officer_id && { assigned_officer__id: String(assigned_officer_id) }),
            ...(email && { email }),
            ...(phone_number && { phone_number }),
            ...(phone_number_bvn && { phone_number_bvn }),
        });
        const urlPath = `${ticketConstants_1.default}?${queryParams}`;
        return await network_1.fetchBackend(urlPath, constants_1.HTTP.GET, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getTicket = toolkit_1.createAsyncThunk('tickets/get', async ({ ticket_id }, { getState, rejectWithValue }) => {
    try {
        const url = ticketConstants_1.TICKET_DETAIL_OR_UPDATE_URL.replace(constants_1.TICKET_ID, ticket_id);
        const { auth } = getState();
        const response = await network_1.fetchBackend(url, constants_1.HTTP.GET, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.createTicket = toolkit_1.createAsyncThunk('tickets/create', async (payload, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const body = { ...payload };
        const response = await network_1.fetchBackend(ticketConstants_1.default, constants_1.HTTP.POST, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.updateTicket = toolkit_1.createAsyncThunk('tickets/update', async (payload, { getState, rejectWithValue }) => {
    try {
        const url = ticketConstants_1.TICKET_DETAIL_OR_UPDATE_URL.replace(constants_1.TICKET_ID, payload.ticket_id);
        const { auth } = getState();
        const body = { ...payload };
        const response = await network_1.fetchBackend(url, constants_1.HTTP.PATCH, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.getComments = toolkit_1.createAsyncThunk('tickets/comments', async ({ ticket_id }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const url = ticketConstants_1.COMMENTS_CREATE_OR_LIST_URL.replace(constants_1.TICKET_ID, ticket_id);
        return await network_1.fetchBackend(url, constants_1.HTTP.GET, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.createComment = toolkit_1.createAsyncThunk('tickets/comments/create', async (payload, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const body = { ...payload };
        const url = ticketConstants_1.COMMENTS_CREATE_OR_LIST_URL.replace(constants_1.TICKET_ID, payload.ticket_id);
        const response = await network_1.fetchBackend(url, constants_1.HTTP.POST, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.updateComment = toolkit_1.createAsyncThunk('tickets/comments/update', async (payload, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const body = { ...payload };
        const url = ticketConstants_1.COMMENTS_DETAIL_OR_UPDATE_URL.replace(constants_1.TICKET_ID, payload.ticket_id).replace(constants_1.COMMENT_ID, payload.comment_id);
        const response = await network_1.fetchBackend(url, constants_1.HTTP.PATCH, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.deleteComment = toolkit_1.createAsyncThunk('tickets/comments/get', async ({ ticket_id, comment_id }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const url = ticketConstants_1.COMMENTS_DETAIL_OR_UPDATE_URL.replace(constants_1.TICKET_ID, ticket_id).replace(constants_1.COMMENT_ID, comment_id);
        const response = await network_1.fetchBackend(url, constants_1.HTTP.DELETE, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const ticketSlice = toolkit_1.createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        resetTicketState() {
            return initialState;
        },
        resetSelectedTicket(state) {
            return {
                ...state,
                selectedTicket: null,
                selectedTicketComments: [],
            };
        },
        setSelectedTicket(state, action) {
            return { ...state, selectedTicket: action.payload };
        },
        setSelectedTicketComments(state, action) {
            return { ...state, selectedTicketComments: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getTickets.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getTickets.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            tickets: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getTickets.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.createTicket.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.createTicket.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedTicket: payload,
            tickets: [payload, ...state.tickets],
        }))
            .addCase(exports.createTicket.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.getTicket.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getTicket.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedTicket: payload,
        }))
            .addCase(exports.getTicket.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.updateTicket.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.updateTicket.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedTicket: payload,
        }))
            .addCase(exports.updateTicket.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.getComments.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getComments.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedTicketComments: payload.results,
        }))
            .addCase(exports.getComments.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.createComment.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.createComment.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedTicketComments: [...state.selectedTicketComments, payload],
        }))
            .addCase(exports.createComment.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.updateComment.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.updateComment.fulfilled, (state, action) => {
            const updatedComment = action.payload; // Assuming the updated comment is received in the payload
            // Find the index of the comment to be updated in the selectedTicketComments array
            const commentIndex = state.selectedTicketComments.findIndex((comment) => comment.id === updatedComment.id);
            if (commentIndex !== -1) {
                const updatedComments = [...state.selectedTicketComments];
                updatedComments[commentIndex] = updatedComment;
                return {
                    ...state,
                    loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
                    selectedTicketComments: updatedComments,
                };
            }
            return state; // Return the current state if the comment is not found
        })
            .addCase(exports.updateComment.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.deleteComment.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.deleteComment.fulfilled, (state, action) => {
            const deletedCommentId = action.meta.arg.comment_id;
            // Filter out the deleted comment from the selectedTicketComments array
            const updatedComments = state.selectedTicketComments.filter((comment) => comment.id !== deletedCommentId);
            return {
                ...state,
                loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
                selectedTicketComments: updatedComments,
            };
        })
            .addCase(exports.deleteComment.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        });
    },
});
_a = ticketSlice.actions, exports.resetTicketState = _a.resetTicketState, exports.resetSelectedTicket = _a.resetSelectedTicket, exports.setSelectedTicket = _a.setSelectedTicket, exports.setSelectedTicketComments = _a.setSelectedTicketComments;
exports.default = ticketSlice.reducer;
