"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateOfficerChoices = exports.searchOfficers = exports.validate = exports.generateInitialPayload = void 0;
const ticketConstants_1 = require("../../features/ticket/ticketConstants");
const utils_1 = require("../../utils/utils");
const officersSlice_1 = require("../../features/officer/officersSlice");
const ticketFields = new Map(ticketConstants_1.TICKET_FIELDS.map(({ key, value }) => [key, value]));
const generateInitialPayload = (instance) => {
    const payload = {};
    if (!instance) {
        ticketFields.forEach((value, key) => {
            payload[key] = value.default;
        });
        return payload;
    }
    Array.from(ticketFields.keys()).forEach((key) => {
        const value = instance[key];
        payload[key] = value;
    });
    return payload;
};
exports.generateInitialPayload = generateInitialPayload;
const validate = (values) => {
    const errors = {};
    Object.entries(values).forEach(([field, value]) => {
        if (ticketConstants_1.REQUIRED_FIELDS.includes(field) && utils_1.isEmptyOrUndefined(value)) {
            errors[field] = 'This field is required';
        }
    });
    return errors;
};
exports.validate = validate;
const searchOfficers = (dispatch, query) => {
    dispatch(officersSlice_1.getOfficers({ first_name: query }));
};
exports.searchOfficers = searchOfficers;
const generateOfficerChoices = (officers) => {
    return [
        { value: '', label: 'Select officer' },
        ...officers.map((agent) => {
            return { value: agent.id, label: `${agent.first_name} ${agent.last_name}` };
        }),
    ];
};
exports.generateOfficerChoices = generateOfficerChoices;
