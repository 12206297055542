"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELD_MAPPING = exports.SCHEDULING_TYPE_CHOICES = exports.SCHEDULE_CATEGORY_CHOICES = exports.CDR_FILE_AVAILABILITY_CHOICES = exports.CALL_TYPE_CHOICES = exports.STATUS_CHOICES = void 0;
const typesConstants_1 = require("../../constants/typesConstants");
const qualityAssuranceConstants_1 = require("../../features/qualityAssurance/qualityAssuranceConstants");
const utils_1 = require("../../utils/utils");
const DISPOSITION_CHOICES = [
    { value: '', label: 'Select disposition' },
    { value: 'BUSY', label: 'Busy' },
    { value: 'ANSWERED', label: 'Answered' },
    { value: 'NO ANSWER', label: 'No Answer' },
];
exports.STATUS_CHOICES = [
    { value: '', label: 'Select status' },
    { value: qualityAssuranceConstants_1.NOT_REVIEWED, label: 'Not Reviewed' },
    { value: qualityAssuranceConstants_1.REVIEWED, label: 'Reviewed' },
    { value: qualityAssuranceConstants_1.NETWORK_ISSUE, label: 'Network Issue' },
    { value: qualityAssuranceConstants_1.TECHNICAL_ISSUE, label: 'Technical Issue' },
    { value: qualityAssuranceConstants_1.DROPPED, label: 'Call Dropped' },
];
exports.CALL_TYPE_CHOICES = [
    { value: '', label: 'Select call type' },
    { value: qualityAssuranceConstants_1.COLLECTIONS, label: 'Collections' },
    { value: qualityAssuranceConstants_1.TO_SUPPORT, label: 'To Support' },
    { value: qualityAssuranceConstants_1.FROM_SUPPORT, label: 'From Support' },
    { value: qualityAssuranceConstants_1.KEY_ACCOUNT, label: 'Key Account' },
    { value: qualityAssuranceConstants_1.COLLECTIONS_ASSISTANT, label: 'Collection Assistant' },
    { value: qualityAssuranceConstants_1.IVR, label: 'IVR' },
];
exports.CDR_FILE_AVAILABILITY_CHOICES = [
    { value: '', label: 'Select file availability' },
    { value: 1, label: 'No file' },
    { value: 2, label: 'With file' },
];
const SCHEDULE_CATEGORY_COLLECTIONS = 0;
const SCHEDULE_CATEGORY_PREDUE = 1;
const SCHEDULE_CATEGORY_STUCK_IN_KYC_PENDING = 2;
const SCHEDULE_CATEGORY_CANCELLED_LOANS = 3;
const SCHEDULE_CATEGORY_USER_REACTIVATION = 4;
const SCHEDULE_CATEGORY_MANUAL_CONFIRMATION = 5;
const SCHEDULE_CATEGORY_LOAN_APPLICATION_ATTEMPT = 6;
exports.SCHEDULE_CATEGORY_CHOICES = [
    { value: '', label: 'Select category' },
    { value: SCHEDULE_CATEGORY_COLLECTIONS, label: 'Collections' },
    { value: SCHEDULE_CATEGORY_PREDUE, label: 'Predue' },
    { value: SCHEDULE_CATEGORY_STUCK_IN_KYC_PENDING, label: 'Stuck in KYC Pending' },
    { value: SCHEDULE_CATEGORY_CANCELLED_LOANS, label: 'Cancelled Loans' },
    { value: SCHEDULE_CATEGORY_USER_REACTIVATION, label: 'User reactivation' },
    { value: SCHEDULE_CATEGORY_MANUAL_CONFIRMATION, label: 'Manual Confirmation' },
    { value: SCHEDULE_CATEGORY_LOAN_APPLICATION_ATTEMPT, label: 'Loan application attempt' },
];
exports.SCHEDULING_TYPE_CHOICES = [
    { value: '', label: 'Select scheduling Type' },
    { value: typesConstants_1.SCHEDULING_TYPE_MANUAL, label: 'Manual' },
    { value: typesConstants_1.SCHEDULING_TYPE_AUTOMATED, label: 'Automated' },
];
exports.FIELD_MAPPING = new Map([
    ['review_status', utils_1.mapStatusChoices(exports.STATUS_CHOICES)],
    ['category', utils_1.mapStatusChoices(exports.SCHEDULE_CATEGORY_CHOICES)],
    ['call_type', utils_1.mapStatusChoices(exports.CALL_TYPE_CHOICES)],
    ['file_availability', utils_1.mapStatusChoices(exports.CDR_FILE_AVAILABILITY_CHOICES)],
    ['scheduling_type', utils_1.mapStatusChoices(exports.SCHEDULING_TYPE_CHOICES)],
]);
exports.default = DISPOSITION_CHOICES;
